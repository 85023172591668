<template>
  <div class="learnMore">
    <h2>了解更多</h2>
    <div class="moreBox">
      <div class="moreItem" v-for="(item,index) in list.filter(it=>it.name!=routeName)" :key="index" @click="$router.push({name:item.name})">
        <img class="moreIcon" :src="require(`@/assets/image/home/${item.icon}`)" alt="标志icon">
        <h3>{{item.label}}</h3>
        <img class="moreArrow" src="@/assets/image/productCenter/arrow.png" alt="arrow">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'learnMore',
  data () {
    return {
      list: [
        {
          icon: 'product-icon1.png',
          name: 'product-orgCert',
          label: '机构证书'
        },
        {
          icon: 'product-icon2.png',
          name: 'product-personCert',
          label: '个人证书'
        },
        {
          icon: 'product-icon3.png',
          name: 'product-sealBox',
          label: '智能印章一体机'
        },
        {
          icon: 'product-icon4.png',
          name: 'product-eSeal',
          label: '电子签章系统'
        },
        {
          icon: 'product-icon5.png',
          name: 'product-verifySign',
          label: '签名验签'
        },
        {
          icon: 'product-icon6.png',
          name: 'product-timestamp',
          label: '可信时间戳'
        },
        {
          icon: 'product-icon7.png',
          name: 'product-mobileShield',
          label: '手机盾'
        }
      ],
      routeName: ''
    }
  },
  created () {
    this.routeName = this.$route.name
  }
}
</script>

<style lang="scss" scoped>
.learnMore {
  padding: 0 0.64rem 0;
  box-sizing: border-box;
  h2 {
    font-size: 0.48rem;
    color: #303b50;
    line-height: 0.67rem;
    margin-bottom: 0.77rem;
    text-align: center;
  }
}
.moreItem {
  width: 6.22rem;
  height: 1.24rem;
  background: #ffffff;
  box-shadow: 0px 0.04rem 0.16rem 0px rgba(31, 37, 81, 0.11);
  border-radius: 0.02rem;
  border: 1px solid #ffffff;
  margin-bottom: 0.4rem;
  padding: 0 0.32rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  .moreIcon {
    width: 0.6rem;
    height: 0.6rem;
    margin-right: 16px;
  }
  h3 {
    flex: 1;
    font-size: 0.28rem;
    font-weight: 400;
    color: #303b50;
    line-height: 0.4rem;
  }
  .moreArrow {
    width: 0.4rem;
    height: 0.15rem;
  }
}
</style>